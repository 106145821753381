<template>
  <div class="main-content">
    <h3 class="marginBottom20">车辆行驶轨迹</h3>
    <div>
      <el-button @click="goBack()" style="margin-bottom:20px">返回</el-button>
    </div>
    <div id="container" v-if="path.length > 0" >
      <div class="prop" v-if="propsState">
        <div class="title">选中位置</div>
        <div class="time">时间：{{ geometry.time }}</div>
        <div class="textMeun">
          <div class="text">经度：{{ geometry.lat }}</div>
          <div class="text">纬度：{{ geometry.lng }}</div>
          <div class="text">位置：{{ geometry.address }}</div>
        </div>
      </div>
    </div>
    <div id="container" v-else>
      暂无数据
    </div>
  </div>
</template>

<script>
import { manageTravel, travelAdress } from "@/api/order/index";
let TMap, map;
export default {
  name: "TMap",
  data() {
    return {
      map: null,
      path: [],
      polylineLayer: null,
      marker: null,
      propsState: false,
      geometry: {
        time: "",
        lat: "",
        lng: "",
        address: "",
      },
    };
  },
  mounted() {
    this.$route.query.id && this.getView();
  },
  methods: {
    initMap() {
      TMap = window.TMap;
      //设置地图中心点

      let center = new TMap.LatLng(
        this.path[0].latitude,
        this.path[0].longitude
      );
      let paths = [];
      this.path.map((item) => {
        paths.push(new TMap.LatLng(item.latitude, item.longitude));
      });

      this.map = new TMap.Map("container", {
        zoom: 30,
        center: center,
        // baseMap: {  // 设置卫星地图
        //   type: 'satellite'
        // }
      });

      this.polylineLayer = new TMap.MultiPolyline({
        map: this.map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          style_blue: new TMap.PolylineStyle({
            color: "rgb(64, 158, 255)", // 线填充色
            width: 6, // 折线宽度
            borderWidth: 2, // 边线宽度
            borderColor: "#FFF", // 边线颜色
            lineCap: "round", // 线端头方式
            eraseColor: "rgb(172,172,172)", //擦除路径的颜色
            showArrow:true,
            arrowOptions:{
              width:10,
              height:10,
              space:10
            }
          }),
        },
        geometries: [
          {
            id: "erasePath",
            styleId: "style_blue",
            paths: paths,
          },
        ],
      });
      let geometriesList = [];
      this.path.map((item, index) => {
        if (index === 0) {
          // geometriesList.unshift({
          //   id: "meun" + index,
          //   styleId: "start",
          //   position: new TMap.LatLng(item.latitude, item.longitude),
          // });
        } else if (index === paths.length - 1) {
          // geometriesList.push({
          //   id: "meun" + index,
          //   styleId: "end",
          //   position: new TMap.LatLng(item.latitude, item.longitude),
          // });
        } else {
          geometriesList.push({
            id: "meun" + index,
            styleId: "meun",
            position: new TMap.LatLng(item.latitude, item.longitude),
          });
        }
      });
      geometriesList.push({
            id: 'start',
            styleId: "start",
            zIndex:999999999999,
            position: new TMap.LatLng(this.path[0].latitude, this.path[0].longitude),
          });
          geometriesList.push({
            id: 'end',
            styleId: "end",
            zIndex:999999,
            position: new TMap.LatLng(this.path[paths.length - 1].latitude, this.path[paths.length - 1].longitude),
          });
      this.marker = new TMap.MultiMarker({
        map: this.map, // 绘制到目标地图
        styles: {
          start: new TMap.MarkerStyle({
            width: 25,
            height: 37,
            anchor: { x: 16, y: 32 },
            src:
              "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png",
          }),
          meun: new TMap.MarkerStyle({
            width: 25,
            height: 37,
            anchor: { x: 16, y: 32 },
            // src:'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/menu',
          }),
          end: new TMap.MarkerStyle({
            width: 25,
            height: 37,
            anchor: { x: 16, y: 32 },
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png",
          }),
        },
        geometries: geometriesList,
      });
      this.marker.on("click", this.eventClick);
    },
    eventClick(e) {
      console.log(e.geometry);
      travelAdress({
        latitude: e.geometry.position.lat,
        longitude: e.geometry.position.lng,
      }).then((res) => {
        if (res.code === 200) {
          this.propsState = true;
          console.log(res, 11111);
          this.geometry = {
            time: this.path[Number(e.geometry.id.replace("meun", ""))].time,
            address: res.data.address,
            lat: e.geometry.position.lat,
            lng: e.geometry.position.lng,
          };
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    getView() {
      this.loading = true;
      manageTravel({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          // res.data.map((item) => {
          //   paths.push(new TMap.LatLng(item.latitude, item.longitude));
          // });
          this.path = res.data;
          this.initMap();
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style>
#container {
  height: 600px;
  position: relative;
}
.prop {
  position: absolute;
  left: 30%;
  bottom: 20px;
  background-color: #fff;
  padding: 20px;
  z-index: 9999;
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.title {
  font-weight: bold;
  font-size: 16px;
}
.time {
  font-size: 16px;
  margin-top: 5px;
}
.textMeun {
  font-size: 14px;
  color: #333;
  margin-top: 5px;
  line-height: 20px;
}
</style>
